import { DefaultLayout } from 'components';
import {
  ContactPage,
  HomePage,
  NamestajPage,
  OrdenjePage,
  OstaloPage,
  PageNotFound,
  PorculanPage,
  RedeemPage,
  SatoviPage,
  SlikePage,
  UpaljaciPage,
  ZlatoPage,
} from 'pages';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {
  KONTACT_RUTA,
  OTKUPLJUJEMO_NAMESTAJ_RUTA,
  OTKUPLJUJEMO_ORDENJE_RUTA,
  OTKUPLJUJEMO_OSTALO_RUTA,
  OTKUPLJUJEMO_PORCULAN_RUTA,
  OTKUPLJUJEMO_RUTA,
  OTKUPLJUJEMO_SATOVE_RUTA,
  OTKUPLJUJEMO_SLIKE_RUTA,
  OTKUPLJUJEMO_UPALJACE_RUTA,
  OTKUPLJUJEMO_ZLATO_RUTA,
  ROOT_ROUTE,
} from 'utils/helper';

export const AppRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<DefaultLayout />}>
          <Route path={ROOT_ROUTE} element={<HomePage />} />
          <Route path={KONTACT_RUTA} element={<ContactPage />} />
          {/* <Route path={OTKUP_PROCENA_RUTA} element={<OtkupProcena />} /> */}
          <Route path={OTKUPLJUJEMO_RUTA} element={<RedeemPage />} />
          <Route path={OTKUPLJUJEMO_ZLATO_RUTA} element={<ZlatoPage />} />
          <Route path={OTKUPLJUJEMO_ORDENJE_RUTA} element={<OrdenjePage />} />
          <Route path={OTKUPLJUJEMO_UPALJACE_RUTA} element={<UpaljaciPage />} />
          <Route path={OTKUPLJUJEMO_SATOVE_RUTA} element={<SatoviPage />} />
          <Route path={OTKUPLJUJEMO_SLIKE_RUTA} element={<SlikePage />} />
          <Route path={OTKUPLJUJEMO_PORCULAN_RUTA} element={<PorculanPage />} />
          <Route path={OTKUPLJUJEMO_NAMESTAJ_RUTA} element={<NamestajPage />} />
          <Route path={OTKUPLJUJEMO_OSTALO_RUTA} element={<OstaloPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};
