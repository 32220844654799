import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  OTKUPLJUJEMO_NAMESTAJ_RUTA,
  OTKUPLJUJEMO_ORDENJE_RUTA,
  OTKUPLJUJEMO_OSTALO_RUTA,
  OTKUPLJUJEMO_PORCULAN_RUTA,
  OTKUPLJUJEMO_SATOVE_RUTA,
  OTKUPLJUJEMO_SLIKE_RUTA,
  OTKUPLJUJEMO_UPALJACE_RUTA,
  OTKUPLJUJEMO_ZLATO_RUTA,
} from 'utils/helper';
import OtkupljujemoGoldJpg from '../../images/otkupljujemo/gold-min.jpg';
import OtkupljujemoGoldWebp from '../../images/otkupljujemo/gold-min.webp';
import OtkupljujemoNamestajJpg from '../../images/otkupljujemo/namestaj-min.jpg';
import OtkupljujemoNamestajWebp from '../../images/otkupljujemo/namestaj-min.webp';
import OtkupljujemoOrdenjeJpg from '../../images/otkupljujemo/ordenje-min.jpg';
import OtkupljujemoOrdenjeWebp from '../../images/otkupljujemo/ordenje-min.webp';
import OtkupljujemoOstaloJpg from '../../images/otkupljujemo/ostalo-min.jpg';
import OtkupljujemoOstaloWebp from '../../images/otkupljujemo/ostalo-min.webp';
import OtkupljujemoPenkalaJpg from '../../images/otkupljujemo/penkala-min.jpg';
import OtkupljujemoPenkalaWebp from '../../images/otkupljujemo/penkala-min.webp';
import OtkupljujemoPorculaneJpg from '../../images/otkupljujemo/porculan-min.jpg';
import OtkupljujemoPorculaneWebp from '../../images/otkupljujemo/porculan-min.webp';
import OtkupljujemoSatoveJpg from '../../images/otkupljujemo/satovi-min.jpg';
import OtkupljujemoSatoveWebp from '../../images/otkupljujemo/satovi-min.webp';
import OtkupljujemoSlikeJpg from '../../images/otkupljujemo/slike-min.jpg';
import OtkupljujemoSlikeWebp from '../../images/otkupljujemo/slike-min.webp';
import './redeem.scss';

const RedeemPage = () => {
  const { t } = useTranslation();

  const response = () => {
    const response = [
      {
        path: OTKUPLJUJEMO_ZLATO_RUTA,
        webpURL: OtkupljujemoGoldWebp,
        jpgURL: OtkupljujemoGoldJpg,
        alt: 'zlato i srebro',
        h2: `${t('zlato')} - ${t('srebro')}`,
      },
      {
        path: OTKUPLJUJEMO_ORDENJE_RUTA,
        webpURL: OtkupljujemoOrdenjeWebp,
        jpgURL: OtkupljujemoOrdenjeJpg,
        alt: 'ordenje i oruzje',
        h2: `${t('ordenje')} - ${t('oruzje')}`,
      },
      {
        path: OTKUPLJUJEMO_UPALJACE_RUTA,
        webpURL: OtkupljujemoPenkalaWebp,
        jpgURL: OtkupljujemoPenkalaJpg,
        alt: 'penkala i upaljaci',
        h2: `${t('penkala')} - ${t('upaljaci')}`,
      },
      {
        path: OTKUPLJUJEMO_SATOVE_RUTA,
        webpURL: OtkupljujemoSatoveWebp,
        jpgURL: OtkupljujemoSatoveJpg,
        alt: 'satovi',
        h2: `${t('satovi')}`,
      },
      {
        path: OTKUPLJUJEMO_SLIKE_RUTA,
        webpURL: OtkupljujemoSlikeWebp,
        jpgURL: OtkupljujemoSlikeJpg,
        alt: 'slike i ikone',
        h2: `${t('slike')} - ${t('ikone')}`,
      },
      {
        path: OTKUPLJUJEMO_PORCULAN_RUTA,
        webpURL: OtkupljujemoPorculaneWebp,
        jpgURL: OtkupljujemoPorculaneJpg,
        alt: 'porculan i staklo',
        h2: `${t('porculan')} - ${t('staklo')}`,
      },
      {
        path: OTKUPLJUJEMO_NAMESTAJ_RUTA,
        webpURL: OtkupljujemoNamestajWebp,
        jpgURL: OtkupljujemoNamestajJpg,
        alt: 'namestaj i lusteri',
        h2: `${t('namestaj')}`,
      },
      {
        path: OTKUPLJUJEMO_OSTALO_RUTA,
        webpURL: OtkupljujemoOstaloWebp,
        jpgURL: OtkupljujemoOstaloJpg,
        alt: 'ostalo',
        h2: `${t('ostalo')}`,
      },
    ];
    return response;
  };

  const responseOtkupljujemo = response();
  return (
    <section className="oa-otkupljujemo">
      {responseOtkupljujemo.map((item, index) => {
        return (
          <Link to={item.path} className="oa-otkupljujemo--item" key={index}>
            <picture key={index}>
              <source srcSet={item.webpURL} type="image/webp" />
              <source srcSet={item.jpgURL} type="image/jpeg" />
              <img
                src={item.jpgURL}
                alt={item.alt}
                width="500px"
                height="467px"
              />
            </picture>
            <div className="oa-otkupljujemo--item-desc">
              <h2>{item.h2}</h2>
              <span />
            </div>
          </Link>
        );
      })}
    </section>
  );
};

export default RedeemPage;
