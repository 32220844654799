import KontaktJpg from 'images/pocetna/kontakt-min.jpg';
import KontaktWebp from 'images/pocetna/kontakt-min.webp';
import OtkupJpg from 'images/pocetna/otkup-min.jpg';
import OtkupWebp from 'images/pocetna/otkup-min.webp';
import OtkupljujemoJpg from 'images/pocetna/otkupljujemo-min.jpg';
import OtkupljujemoWebp from 'images/pocetna/otkupljujemo-min.webp';
import Pocetna from 'images/pocetna/pravaslika-min.png';
import ProcenaJpg from 'images/pocetna/procena-min.jpg';
import ProcenaWebp from 'images/pocetna/procena-min.webp';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  KONTACT_RUTA,
  OTKUPLJUJEMO_RUTA,
  OTKUP_PROCENA_RUTA,
} from 'utils/helper';
import './home.scss';

const HomePage = () => {
  const { t } = useTranslation();
  return (
    <section>
      <article className="oa-dashboard-main-section">
        <img src={Pocetna} alt="Pocetna slika - Nebojsa Molerović" />
        <div className="oa-dashboard-main-section--item">
          <h1>Nebojša Molerović</h1>
          <p>
            {t('dashbordTest')}{' '}
            <a href="tel:+381600839974" style={{ whiteSpace: 'nowrap' }}>
              {t('telefon060')}
            </a>
          </p>
        </div>
      </article>
      <article className="oa-dashboard-box-section">
        <Link to={OTKUPLJUJEMO_RUTA} className="oa-dashboard-box-section--item">
          <picture>
            <source srcSet={OtkupljujemoWebp} type="image/webp" />
            <source srcSet={OtkupljujemoJpg} type="image/jpeg" />
            <img src={OtkupljujemoJpg} alt="Otkupljujemo" />
          </picture>
          <div className="oa-dashboard-box-section--item-overlay">
            <p>{t('otkupljujemo')}</p>
          </div>
        </Link>
        <Link
          to={OTKUP_PROCENA_RUTA}
          className="oa-dashboard-box-section--item"
        >
          <picture>
            <source srcSet={OtkupWebp} type="image/webp" />
            <source srcSet={OtkupJpg} type="image/jpeg" />
            <img src={OtkupJpg} alt="Otkup" />
          </picture>
          <div className="oa-dashboard-box-section--item-overlay">
            <p>{t('otkup')}</p>
          </div>
        </Link>
        <Link
          to={OTKUP_PROCENA_RUTA}
          className="oa-dashboard-box-section--item"
        >
          <picture>
            <source srcSet={ProcenaWebp} type="image/webp" />
            <source srcSet={ProcenaJpg} type="image/jpeg" />
            <img src={ProcenaJpg} alt="Procena" />
          </picture>
          <div className="oa-dashboard-box-section--item-overlay">
            <p>{t('procena')}</p>
          </div>
        </Link>
        <Link to={KONTACT_RUTA} className="oa-dashboard-box-section--item">
          <picture>
            <source srcSet={KontaktWebp} type="image/webp" />
            <source srcSet={KontaktJpg} type="image/jpeg" />
            <img src={KontaktJpg} alt="Kontakt" />
          </picture>
          <div className="oa-dashboard-box-section--item-overlay">
            <p>{t('kontakt')}</p>
          </div>
        </Link>
      </article>
    </section>
  );
};

export default HomePage;
