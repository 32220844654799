import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Satovi1jpg from '../../../images/otkupljujemo/satovi/satovi1-min.jpg';
import Satovi1webp from '../../../images/otkupljujemo/satovi/satovi1-min.webp';
import Satovi2jpg from '../../../images/otkupljujemo/satovi/satovi2-min.jpg';
import Satovi2webp from '../../../images/otkupljujemo/satovi/satovi2-min.webp';
import Satovi3jpg from '../../../images/otkupljujemo/satovi/satovi3-min.jpg';
import Satovi3webp from '../../../images/otkupljujemo/satovi/satovi3-min.webp';
import Satovi4jpg from '../../../images/otkupljujemo/satovi/satovi4-min.jpg';
import Satovi4webp from '../../../images/otkupljujemo/satovi/satovi4-min.webp';
import Satovi5jpg from '../../../images/otkupljujemo/satovi/satovi5-min.jpg';
import Satovi5webp from '../../../images/otkupljujemo/satovi/satovi5-min.webp';
import Satovi6jpg from '../../../images/otkupljujemo/satovi/satovi6-min.jpg';
import Satovi6webp from '../../../images/otkupljujemo/satovi/satovi6-min.webp';
import Satovi7jpg from '../../../images/otkupljujemo/satovi/satovi7-min.jpg';
import Satovi7webp from '../../../images/otkupljujemo/satovi/satovi7-min.webp';
import Satovi8jpg from '../../../images/otkupljujemo/satovi/satovi8-min.jpg';
import Satovi8webp from '../../../images/otkupljujemo/satovi/satovi8-min.webp';

const images = [
  { jpgURL: Satovi1jpg, webpURL: Satovi1webp, alt: 'Rolex' },
  { jpgURL: Satovi2jpg, webpURL: Satovi2webp, alt: 'IWC Schaffhausen' },
  { jpgURL: Satovi3jpg, webpURL: Satovi3webp, alt: 'Breitling' },
  { jpgURL: Satovi4jpg, webpURL: Satovi4webp, alt: 'Zenith' },
  { jpgURL: Satovi5jpg, webpURL: Satovi5webp, alt: 'Rolex submariner' },
  { jpgURL: Satovi6jpg, webpURL: Satovi6webp, alt: 'Dzepni sat' },
  { jpgURL: Satovi7jpg, webpURL: Satovi7webp, alt: 'Kaminski sat' },
  { jpgURL: Satovi8jpg, webpURL: Satovi8webp, alt: 'Zidni sat' },
];

const SatoviPage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup">
        <p>
          <span>{t('satovi')}:</span>
          {t('satoviContent')}
        </p>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default SatoviPage;
