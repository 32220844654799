import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Novac1jpg from '../../../images/otkupljujemo/novac/novac1-min.jpg';
import Novac1webp from '../../../images/otkupljujemo/novac/novac1-min.webp';
import Novac2jpg from '../../../images/otkupljujemo/novac/novac2-min.jpg';
import Novac2webp from '../../../images/otkupljujemo/novac/novac2-min.webp';
import Novac3jpg from '../../../images/otkupljujemo/novac/novac3-min.jpg';
import Novac3webp from '../../../images/otkupljujemo/novac/novac3-min.webp';
import Novac4jpg from '../../../images/otkupljujemo/novac/novac4-min.jpg';
import Novac4webp from '../../../images/otkupljujemo/novac/novac4-min.webp';
import Novac5jpg from '../../../images/otkupljujemo/novac/novac5-min.jpg';
import Novac5webp from '../../../images/otkupljujemo/novac/novac5-min.webp';
import Novac6jpg from '../../../images/otkupljujemo/novac/novac6-min.jpg';
import Novac6webp from '../../../images/otkupljujemo/novac/novac6-min.webp';
import Novac7jpg from '../../../images/otkupljujemo/novac/novac7-min.jpg';
import Novac7webp from '../../../images/otkupljujemo/novac/novac7-min.webp';
import Novac8jpg from '../../../images/otkupljujemo/novac/novac8-min.jpg';
import Novac8webp from '../../../images/otkupljujemo/novac/novac8-min.webp';

const images = [
  { jpgURL: Novac1jpg, webpURL: Novac1webp, alt: 'Stari papirni novac' },
  { jpgURL: Novac2jpg, webpURL: Novac2webp, alt: 'Akcije' },
  { jpgURL: Novac3jpg, webpURL: Novac3webp, alt: 'Ukaz za ordenja' },
  { jpgURL: Novac4jpg, webpURL: Novac4webp, alt: 'Razglednica' },
  { jpgURL: Novac5jpg, webpURL: Novac5webp, alt: 'Stari novac' },
  { jpgURL: Novac6jpg, webpURL: Novac6webp, alt: 'Markice' },
  { jpgURL: Novac7jpg, webpURL: Novac7webp, alt: 'Stare igracke' },
  {
    jpgURL: Novac8jpg,
    webpURL: Novac8webp,
    alt: 'Mercedes-Benz 170V oldtimer',
  },
];

const OstaloPage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup">
        <p>
          <span>{t('ostalo')}:</span>
          {t('ostaloContent')}
        </p>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default OstaloPage;
