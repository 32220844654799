import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Orden1jpg from '../../../images/otkupljujemo/ordenje/orden1-min.jpg';
import Orden1webp from '../../../images/otkupljujemo/ordenje/orden1-min.webp';
import Orden2jpg from '../../../images/otkupljujemo/ordenje/orden2-min.jpg';
import Orden2webp from '../../../images/otkupljujemo/ordenje/orden2-min.webp';
import Orden3jpg from '../../../images/otkupljujemo/ordenje/orden3-min.jpg';
import Orden3webp from '../../../images/otkupljujemo/ordenje/orden3-min.webp';
import Orden4jpg from '../../../images/otkupljujemo/ordenje/orden4-min.jpg';
import Orden4webp from '../../../images/otkupljujemo/ordenje/orden4-min.webp';
import Orden5jpg from '../../../images/otkupljujemo/ordenje/orden5-min.jpg';
import Orden5webp from '../../../images/otkupljujemo/ordenje/orden5-min.webp';
import Orden6jpg from '../../../images/otkupljujemo/ordenje/orden6-min.jpg';
import Orden6webp from '../../../images/otkupljujemo/ordenje/orden6-min.webp';
import Orden7jpg from '../../../images/otkupljujemo/ordenje/orden7-min.jpg';
import Orden7webp from '../../../images/otkupljujemo/ordenje/orden7-min.webp';
import Orden8jpg from '../../../images/otkupljujemo/ordenje/orden8-min.jpg';
import Orden8webp from '../../../images/otkupljujemo/ordenje/orden8-min.webp';

const images = [
  { jpgURL: Orden1jpg, webpURL: Orden1webp, alt: 'Orden narodnog heroja' },
  {
    jpgURL: Orden2jpg,
    webpURL: Orden2webp,
    alt: 'Orden belog orla sa macevima ',
  },
  {
    jpgURL: Orden3jpg,
    webpURL: Orden3webp,
    alt: 'Orden junaka socijalistickog rada',
  },
  {
    jpgURL: Orden4jpg,
    webpURL: Orden4webp,
    alt: 'Kokarda Aleksandra Karadjordjevica',
  },
  { jpgURL: Orden5jpg, webpURL: Orden5webp, alt: 'Jatagani' },
  { jpgURL: Orden6jpg, webpURL: Orden6webp, alt: 'Bodezi' },
  { jpgURL: Orden7jpg, webpURL: Orden7webp, alt: 'Sablja' },
  { jpgURL: Orden8jpg, webpURL: Orden8webp, alt: 'Ratna zastava' },
];

const OrdenjePage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup">
        <p>
          <span>{t('ordenje')}:</span>
          {t('ordenjeContent')}
        </p>
        <p>
          <span>{t('oruzje')}:</span>
          {t('oruzjeContent')}
        </p>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default OrdenjePage;
