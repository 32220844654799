export const ROOT_ROUTE = '/';
export const OTKUPLJUJEMO_RUTA = '/otkupljujemo';
export const OTKUPLJUJEMO_ZLATO_RUTA = `${OTKUPLJUJEMO_RUTA}/zlato-srebro`;
export const OTKUPLJUJEMO_ORDENJE_RUTA = `${OTKUPLJUJEMO_RUTA}/ordenje-oruzje`;
export const OTKUPLJUJEMO_UPALJACE_RUTA = `${OTKUPLJUJEMO_RUTA}/penkala-upaljace`;
export const OTKUPLJUJEMO_SATOVE_RUTA = `${OTKUPLJUJEMO_RUTA}/satove`;
export const OTKUPLJUJEMO_SLIKE_RUTA = `${OTKUPLJUJEMO_RUTA}/slike-ikone`;
export const OTKUPLJUJEMO_PORCULAN_RUTA = `${OTKUPLJUJEMO_RUTA}/porculan-staklo`;
export const OTKUPLJUJEMO_NAMESTAJ_RUTA = `${OTKUPLJUJEMO_RUTA}/namestaj`;
export const OTKUPLJUJEMO_OSTALO_RUTA = `${OTKUPLJUJEMO_RUTA}/ostalo`;

export const OTKUP_PROCENA_RUTA = '/otkup-procena';
export const KONTACT_RUTA = '/kontakt';
