import { useTranslation } from 'react-i18next';
import './404.scss';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="page-not-found--wrapper">
      <h1>{t('opsPageNotFound')}</h1>
      <p>{t('pageNotFound')}</p>
    </div>
  );
};

export default PageNotFound;
