import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/en.json';
import translationSRB from './srb/srb.json';

const resources = {
  en: {
    translation: translationEN,
  },
  srb: {
    translation: translationSRB,
  },
};

i18next.use(initReactI18next).init({
  lng: 'srb', // if you're using a language detector, do not define the lng option
  // debug: true,
  resources,
  fallbackLng: 'srb',
  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});
