import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Gold1jpg from '../../../images/otkupljujemo/gold/gold1-min.jpg';
import Gold1webp from '../../../images/otkupljujemo/gold/gold1-min.webp';
import Gold2jpg from '../../../images/otkupljujemo/gold/gold2-min.jpg';
import Gold2webp from '../../../images/otkupljujemo/gold/gold2-min.webp';
import Gold3jpg from '../../../images/otkupljujemo/gold/gold3-min.jpg';
import Gold3webp from '../../../images/otkupljujemo/gold/gold3-min.webp';
import Gold4jpg from '../../../images/otkupljujemo/gold/gold4-min.jpg';
import Gold4webp from '../../../images/otkupljujemo/gold/gold4-min.webp';
import Gold5jpg from '../../../images/otkupljujemo/gold/gold5-min.jpg';
import Gold5webp from '../../../images/otkupljujemo/gold/gold5-min.webp';
import Gold6jpg from '../../../images/otkupljujemo/gold/gold6-min.jpg';
import Gold6webp from '../../../images/otkupljujemo/gold/gold6-min.webp';
import Gold7jpg from '../../../images/otkupljujemo/gold/gold7-min.jpg';
import Gold7webp from '../../../images/otkupljujemo/gold/gold7-min.webp';
import Gold8jpg from '../../../images/otkupljujemo/gold/gold8-min.jpg';
import Gold8webp from '../../../images/otkupljujemo/gold/gold8-min.webp';
import './otkup_zlata.scss';

const images = [
  { jpgURL: Gold1jpg, webpURL: Gold1webp, alt: 'dukat Franz Josef' },
  { jpgURL: Gold2jpg, webpURL: Gold2webp, alt: 'zlatni prsten' },
  { jpgURL: Gold3jpg, webpURL: Gold3webp, alt: 'mindjuse' },
  { jpgURL: Gold4jpg, webpURL: Gold4webp, alt: 'prsten sa cilibarom' },
  { jpgURL: Gold5jpg, webpURL: Gold5webp, alt: 'srebrni svecnjaci' },
  { jpgURL: Gold6jpg, webpURL: Gold6webp, alt: 'srebrna kutija' },
  { jpgURL: Gold7jpg, webpURL: Gold7webp, alt: 'srebrnjak novcic' },
  { jpgURL: Gold8jpg, webpURL: Gold8webp, alt: 'pgrlica od slonovace' },
];

const ZlatoPage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup-zlata">
        <div className="oa-otkup">
          <p>
            <span>{t('zlato')}:</span>
            {t('zlatoContent')}
          </p>
          <p>
            <span>{t('srebro')}:</span>
            {t('srebroContent')}
          </p>
          <div className="grey-field">
            <p>{t('naglasavamoContent')}</p>
          </div>
        </div>
        <ul className="oa-otkup-zlata-table">
          <li>{t('finocaZlata')}</li>
          <li>{t('cena')}</li>
          <li>8 {t('karat')}</li>
          <li>{t('8karata')}</li>
          <li>14 {t('karat')}</li>
          <li>{t('14karata')}</li>
          <li>18 {t('karat')}</li>
          <li>{t('18karata')}</li>
          <li>
            {t('zubno')} 20 {t('karat')}
          </li>
          <li>{t('20karata')}</li>
          <li>
            {t('zlatnici')} 22 {t('karat')}
          </li>
          <li>{t('22karata')}</li>
          <li>23,5 {t('karat')}</li>
          <li>{t('235karata')}</li>
          <li>24 {t('karat')}</li>
          <li>{t('24karata')}</li>
          <li>
            {t('dukat')} FRANC JOZEF 3,49gr {t('nebuseni')}
          </li>
          <li>{t('maliFranja')}</li>
          <li>
            {t('dukat')} FRANC JOZEF 13,96gr {t('nebuseni')}
          </li>
          <li>{t('velikiFranja')}</li>
          <li>{t('srebro')}</li>
          <li>{t('srebroCena')}</li>
        </ul>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default ZlatoPage;
