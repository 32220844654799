import FacebookFooter from 'images/facebook-png-min.png';
import Stefan from 'images/krug-min.png';
import LinkedIn from 'images/linkedin-min.png';
import { useTranslation } from 'react-i18next';
import './footer.scss';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="oa-footer">
      &copy; Otkup antikviteta. {t('svaPrava')}
      <div className="oa-footer--owner">
        Stefan Molerović
        <div className="oa-footer--owner-box">
          <div className="oa-footer--owner-box-top" />
          <div className="oa-footer--owner-box-circle">
            <img src={Stefan} alt="Stefan Molerovic slika" />
          </div>
          <div className="oa-footer--owner-box-bottom">
            <p className="oa-footer--owner-box-bottom-name">Stefan Molerović</p>
            <p className="oa-footer--owner-box-bottom-job">
              Front End Developer
            </p>
            <a
              href="https://rs.linkedin.com/in/stefan-molerovic"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="linkedIn" src={LinkedIn} alt="Linkedin" />
            </a>
            <a
              href="https://www.facebook.com/9stefan2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="facebook" src={FacebookFooter} alt="Facebook" />
            </a>
          </div>
          <div className="oa-footer--owner-box-hidden" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
