import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Lampa1jpg from '../../../images/otkupljujemo/lampe/lampa1-min.jpg';
import Lampa1webp from '../../../images/otkupljujemo/lampe/lampa1-min.webp';
import Lampa2jpg from '../../../images/otkupljujemo/lampe/lampa2-min.jpg';
import Lampa2webp from '../../../images/otkupljujemo/lampe/lampa2-min.webp';
import Lampa3jpg from '../../../images/otkupljujemo/lampe/lampa3-min.jpg';
import Lampa3webp from '../../../images/otkupljujemo/lampe/lampa3-min.webp';
import Lampa4jpg from '../../../images/otkupljujemo/lampe/lampa4-min.jpg';
import Lampa4webp from '../../../images/otkupljujemo/lampe/lampa4-min.webp';
import Lampa5jpg from '../../../images/otkupljujemo/lampe/lampa5-min.jpg';
import Lampa5webp from '../../../images/otkupljujemo/lampe/lampa5-min.webp';
import Lampa6jpg from '../../../images/otkupljujemo/lampe/lampa6-min.jpg';
import Lampa6webp from '../../../images/otkupljujemo/lampe/lampa6-min.webp';
import Lampa7jpg from '../../../images/otkupljujemo/lampe/lampa7-min.jpg';
import Lampa7webp from '../../../images/otkupljujemo/lampe/lampa7-min.webp';
import Lampa8jpg from '../../../images/otkupljujemo/lampe/lampa8-min.jpg';
import Lampa8webp from '../../../images/otkupljujemo/lampe/lampa8-min.webp';

const images = [
  { jpgURL: Lampa1jpg, webpURL: Lampa1webp, alt: 'Lampa' },
  { jpgURL: Lampa2jpg, webpURL: Lampa2webp, alt: 'Stona lampa' },
  { jpgURL: Lampa3jpg, webpURL: Lampa3webp, alt: 'Luster' },
  { jpgURL: Lampa4jpg, webpURL: Lampa4webp, alt: 'Vitrina' },
  { jpgURL: Lampa5jpg, webpURL: Lampa5webp, alt: 'Ram za sliku' },
  { jpgURL: Lampa6jpg, webpURL: Lampa6webp, alt: 'Stari trosed' },
  { jpgURL: Lampa7jpg, webpURL: Lampa7webp, alt: 'Stilsko ogledalo' },
  { jpgURL: Lampa8jpg, webpURL: Lampa8webp, alt: 'Cilim' },
];

const NamestajPage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup">
        <p>
          <span>{t('namestaj')}:</span>
          {t('namestajContent')}
        </p>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default NamestajPage;
