import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import {
  KONTACT_RUTA,
  OTKUPLJUJEMO_NAMESTAJ_RUTA,
  OTKUPLJUJEMO_ORDENJE_RUTA,
  OTKUPLJUJEMO_OSTALO_RUTA,
  OTKUPLJUJEMO_PORCULAN_RUTA,
  OTKUPLJUJEMO_RUTA,
  OTKUPLJUJEMO_SATOVE_RUTA,
  OTKUPLJUJEMO_SLIKE_RUTA,
  OTKUPLJUJEMO_UPALJACE_RUTA,
  OTKUPLJUJEMO_ZLATO_RUTA,
  OTKUP_PROCENA_RUTA,
} from 'utils/helper';

type HelmetHeaderProps = {
  title: string;
  description: string;
};

const HelmetHeader = () => {
  const location = useLocation();

  const pageSubTitles = (): HelmetHeaderProps => {
    const { pathname = '' } = location;
    let title = 'Otkup Antikviteta';
    let description =
      'Otkupljujemo sve vaše antikvitete po najvišoj ceni. Isplata odmah. Profesionalno vršenje procene. Proverite zašto smo najbolji! 060/08-39-974';
    switch (pathname) {
      case KONTACT_RUTA:
        title = `${title} - kontakt`;
        description =
          'Kontaktirajte nas putem telefona na 060/08-39-973 (Viber, WhatsApp). Vaš Otkup Antikviteta';
        return { title, description };
      case OTKUPLJUJEMO_ZLATO_RUTA:
        title = `${title} - zlato i srebro`;
        description =
          'Otkupljujemo zlato, srebro, lomljeno zlato, dukate, zubno zlato, srebrni escajg, srebrne kovanice, itd..';
        return { title, description };
      case OTKUPLJUJEMO_ORDENJE_RUTA:
        title = `${title} - ordenje i oruzje`;
        description =
          'Otkupljujemo ordenje, oruzje, sablje, bodeze, kubure, jatagane, oznake, cinove, epolete, znacke, itd..';
        return { title, description };
      case OTKUPLJUJEMO_SATOVE_RUTA:
        title = `${title} - satovi`;
        description =
          'Otkupljujemo satove, rucne satove, dzepne satove, zidne satove, kaminske satove, stone satove, itd..';
        return { title, description };
      case OTKUPLJUJEMO_UPALJACE_RUTA:
        title = `${title} - penkala i upaljaci`;
        description = 'Otkupljujemo penkala, upaljace, hemijske olovke, itd..';
        return { title, description };
      case OTKUPLJUJEMO_SLIKE_RUTA:
        title = `${title} - slike i ikone`;
        description =
          'Otkupljujemo slike, ikone, duboreze, grafike, crteze, ulje na platnu, itd.';
        return { title, description };
      case OTKUPLJUJEMO_PORCULAN_RUTA:
        title = `${title} - porculan i staklo`;
        description =
          'Otkupljujemo porculan, staklo, vaze, case, cinije, figure od porculana, tanjire, itd.';
        return { title, description };
      case OTKUPLJUJEMO_NAMESTAJ_RUTA:
        title = `${title} - namestaj`;
        description =
          'Otkupljujemo namestaj, komode, ogledala, stolove, vitrine, stolice, tepihe, itd..';
        return { title, description };
      case OTKUPLJUJEMO_OSTALO_RUTA:
        title = `${title} - ostalo`;
        description =
          'Otkupljujemo stare igracke, stara dokumenta, dopisnice, razglednice, stare fotografije, Mercedes 170 oldtimer, papirni novac, metalni novac, itd..';
        return { title, description };
      case OTKUPLJUJEMO_RUTA:
        title = `${title} - otkupljujemo`;
        description =
          'Otkup Antikviteta se bavi otkupom zlata i srebra, ordenja i oruzja, satova, penkala i upaljaca, slika i ikona, porculana i stakla, namestaja i ostalog.';
        return { title, description };
      case OTKUP_PROCENA_RUTA:
        title = `${title} - otkup ili procena`;
        return { title, description };
      default:
        return { title, description };
    }
  };

  const { title, description } = pageSubTitles();
  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta property="og:url" content="http://otkupantikviteta.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="http://www.otkupantikviteta.com/static/media/gold1-min.ea7a01b2.jpg"
      />
      <meta
        name="tags"
        content="otkup antikviteta, otkup antikviteta Beograd, otkup antikviteta Srbija, otkup zlata, otkup zlata Beograd, otkup srebra, otkup srebra Beograd, zlato, srebro, zlatara, zlatara Beograd, otkup ordenja, otkup ordenja Beograd, zlato 14 karata cena, otkup medalja, plakete od plemenitog metala, otkup slika, otkup satova, otkup starih satova, aktikviteti, starine, kupujem, otkup penkala"
      />
      <meta
        name="keywords"
        content="otkup antikviteta, otkup antikviteta Beograd, otkup antikviteta Srbija, otkup zlata, otkup zlata Beograd, otkup srebra, otkup srebra Beograd, zlato, srebro, zlatara, zlatara Beograd, otkup ordenja, otkup ordenja Beograd, zlato 14 karata cena, otkup medalja, plakete od plemenitog metala, otkup slika, otkup satova, otkup starih satova, aktikviteti, starine, kupujem, otkup penkala"
      />
      <title>{title}</title>
    </Helmet>
  );
};

export default HelmetHeader;
