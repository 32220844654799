type Images = { jpgURL: string; webpURL: string; alt: string };

const RenderImages = ({ images = [] }: { images: Images[] }) => {
  return images.map((item, index) => {
    return (
      <picture key={index}>
        <source srcSet={item.webpURL} type="image/webp" />
        <source srcSet={item.jpgURL} type="image/jpeg" />
        <img src={item.jpgURL} alt={item.alt} style={{ width: '100%' }} />
      </picture>
    );
  });
};

export default RenderImages;
