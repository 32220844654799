import axios, { AxiosResponse } from 'axios';

export function fetchEmailDataKontakt() {
  // .then(() => {
  //   dispatch({ type: SUCCESS_MESSAGE, payload: 'Vaša poruka je uspešno poslata!' });
  //   dispatch({ type: RESPONSE_RECEIVED });
  // })
  // .catch((error) => {
  //   console.error('Ne radi kontakt forma!', error);
  //   dispatch({ type: ERROR_MESSAGE, payload: 'Došlo je do greške! Poruka se nije poslala, molimo pokušajte ponovo!' });
  //   dispatch({ type: RESPONSE_RECEIVED });
  // });
}

export function fetchEmailDataOtkupProcena() {
  // .then(function () {
  //   dispatch({ type: SUCCESS_MESSAGE, payload: 'Vaša poruka je uspešno poslata!' });
  //   dispatch({ type: RESPONSE_RECEIVED });
  // })
  // .catch(function (error) {
  //   console.error('Ne radi otkup procena forma!', error);
  //   dispatch({ type: ERROR_MESSAGE, payload: 'Došlo je do greške! Poruka se nije poslala, molimo pokušajte ponovo!' });
  //   dispatch({ type: RESPONSE_RECEIVED });
  // });
}

export type ContactRequest = {
  imeKontakt: string;
  emailKontakt: string;
  naslov?: string;
  poruka: string;
};

export type RedeemRequest = {
  otkup: boolean;
  procena: boolean;
  ime: string;
  email: string;
  telefon: string;
  cena: string;
  opis: string;
};

const Api = axios.create({
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const sendContactAction = async (values: ContactRequest) => {
  const response: AxiosResponse = await Api.post(
    'https://node-send-email.herokuapp.com/kontakt',
    values,
  );
  return response.data;
};

export const sendRequestAction = async (values: RedeemRequest) => {
  const response: AxiosResponse = await Api.post(
    'https://node-send-email.herokuapp.com/send',
    values,
  );
  return response.data;
};
