import { Footer, Header } from 'components';
import { HelmetHeader } from 'components/helmet-header';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
  return (
    <>
      <HelmetHeader />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default DefaultLayout;
