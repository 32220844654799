import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  KONTACT_RUTA,
  OTKUPLJUJEMO_NAMESTAJ_RUTA,
  OTKUPLJUJEMO_ORDENJE_RUTA,
  OTKUPLJUJEMO_OSTALO_RUTA,
  OTKUPLJUJEMO_PORCULAN_RUTA,
  OTKUPLJUJEMO_RUTA,
  OTKUPLJUJEMO_SATOVE_RUTA,
  OTKUPLJUJEMO_SLIKE_RUTA,
  OTKUPLJUJEMO_UPALJACE_RUTA,
  OTKUPLJUJEMO_ZLATO_RUTA,
  ROOT_ROUTE,
} from 'utils/helper';
import FlagSrb from '../../images/flag-srb.svg';
import FlagUk from '../../images/flag-uk.svg';
import './header.scss';

const Header = () => {
  const [openHamburger, setOpenHamburger] = useState(false);
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { pathname = '' } = location;

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleHideSidebar = () => {
    setOpenHamburger(false);
  };

  const otkupljujemoStranice = () => {
    switch (pathname) {
      case OTKUPLJUJEMO_ZLATO_RUTA:
      case OTKUPLJUJEMO_ORDENJE_RUTA:
      case OTKUPLJUJEMO_SATOVE_RUTA:
      case OTKUPLJUJEMO_UPALJACE_RUTA:
      case OTKUPLJUJEMO_SLIKE_RUTA:
      case OTKUPLJUJEMO_PORCULAN_RUTA:
      case OTKUPLJUJEMO_NAMESTAJ_RUTA:
      case OTKUPLJUJEMO_OSTALO_RUTA:
      case OTKUPLJUJEMO_RUTA:
        return true;
      default:
        return false;
    }
  };

  const otkupljujemoRuta = otkupljujemoStranice();

  return (
    <header className="oa-header">
      <nav className="oa-header--nav">
        <div
          className="oa-header--nav-hamburger"
          onClick={() => setOpenHamburger(!openHamburger)}
        >
          <span />
          <span />
          <span />
        </div>
        <ul className={`${openHamburger ? 'visible' : 'hidden'}`}>
          <li>
            <Link
              className={`${pathname === ROOT_ROUTE ? 'active' : ''}`}
              to={ROOT_ROUTE}
              onClick={handleHideSidebar}
            >
              {t('pocetna')}
              <div className="line" />
            </Link>
          </li>
          <li>
            <Link
              className={`${otkupljujemoRuta ? 'active' : ''}`}
              to={OTKUPLJUJEMO_RUTA}
              onClick={handleHideSidebar}
            >
              {t('otkupljujemo')}
              <div className="line" />
            </Link>
          </li>
          {/* <li>
            <Link
              className={`${pathname === OTKUP_PROCENA_RUTA ? 'active' : ''}`}
              to={OTKUP_PROCENA_RUTA}
              onClick={handleHideSidebar}
            >
              {t('otkup')}/{t('procena')}
              <div className="line" />
            </Link>
          </li> */}
          <li>
            <Link
              className={`${pathname === KONTACT_RUTA ? 'active' : ''}`}
              to={KONTACT_RUTA}
              onClick={handleHideSidebar}
            >
              {t('kontakt')}
              <div className="line" />
            </Link>
          </li>
        </ul>
        <div className="oa-header--nav-languages">
          <button onClick={() => changeLanguage('srb')}>
            <img
              src={FlagSrb}
              width="25px"
              height="25px"
              alt="Srpski jezik"
              title="Srpski jezik"
            />
          </button>
          <button onClick={() => changeLanguage('en')}>
            <img
              src={FlagUk}
              width="25px"
              height="25px"
              alt="English language"
              title="English language"
            />
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
