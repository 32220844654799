import { useTranslation } from 'react-i18next';
import Clock from '../../images/clock.svg';
import Envelope from '../../images/envelope.svg';
import Facebook from '../../images/icon-facebook.svg';
import Instagram from '../../images/icon-instagram.svg';
import Phone from '../../images/phone.svg';
import './contact.scss';

const ContactPage = () => {
  const { t } = useTranslation();
  // const [form] = Form.useForm();

  // const { mutate: sendContactActionMutation, isPending } = useMutation({
  //   mutationFn: sendContactAction,
  // });

  // const onFinish = (values: ContactRequest) => {
  //   sendContactActionMutation(values);
  //   console.log('🚀 ~ onFinish ~ values:', values);
  // };
  return (
    <section>
      <article>
        <h1 className="oa-contact-title">{t('kontaktirajteNas')}</h1>
        <div className="oa-contact-boxes">
          <div className="oa-contact-boxes--item">
            <div className="oa-contact-boxes--item-icon">
              <a href="tel:+381600839974">
                <img
                  src={Phone}
                  alt="telefon 1"
                  style={{ marginTop: '1rem' }}
                />
              </a>
            </div>
            <h3>{t('telefon')}</h3>
            <p>
              <a href="tel:+381600839974">{t('telefon060')}</a> (Viber,
              WhatsApp)
            </p>
          </div>
          <div className="oa-contact-boxes--item">
            <div className="oa-contact-boxes--item-icon">
              <a href="mailto:otkupantikviteta@gmail.com">
                <img src={Envelope} alt="email" />
              </a>
            </div>
            <h3>Email</h3>
            <p>
              <a href="mailto:otkupantikviteta@gmail.com">
                otkupantikviteta@gmail.com
              </a>
            </p>
          </div>
          <div className="oa-contact-boxes--item">
            <div
              className="oa-contact-boxes--item-icon"
              style={{ cursor: 'default' }}
            >
              <img src={Clock} alt="sat" />
            </div>
            <h3>{t('radnoVreme')}</h3>
            <p>{t('svakiDan')}</p>
            <p>{t('amPm')}</p>
          </div>
        </div>
        <div className="oa-contact-form">
          {/*  <div>
             <h2 className="oa-contact-form-title">{t('posaljitePoruku')}</h2>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              name="contact-form"
              onFinish={onFinish}
            >
              <Form.Item
                label={<span style={{ color: '#9e9e9e' }}>{t('ime')}</span>}
                name="imeKontakt"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#9e9e9e' }}>Email</span>}
                name="emailKontakt"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#9e9e9e' }}>{t('naslov')}</span>}
                name="naslov"
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#9e9e9e' }}>{t('poruka')}</span>}
                name="poruka"
                rules={[
                  { required: true, message: 'Please input your message!' },
                ]}
              >
                <Input.TextArea size="large" rows={5} maxLength={500} />
              </Form.Item>
              <Button
                key="submit"
                type="primary"
                size="large"
                loading={isPending}
                onClick={form.submit}
                data-cy="add-button"
              >
                {t('posalji')}
              </Button>
            </Form>
            <div className="oa-success-error-message-wrapper">
             {successMessage && (
              <div className="oa-alert-success">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="oa-alert-danger">{errorMessage}</div>
            )} 
            </div>
          </div> */}
          <div style={{ textAlign: 'right' }}>
            <div className="oa-contact-form--aside">
              <h2 className="oa-contact-form--aside-title">
                {t('pratiteNas')}
              </h2>
              <div className="oa-contact-form--aside-social">
                <a
                  href="https://www.facebook.com/profile.php?id=100007575918923"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="facebook-icon-hov"
                    src={Facebook}
                    alt="facebook"
                  />
                  <h3>Facebook</h3>
                </a>
                <a
                  href="https://www.instagram.com/otkupantikviteta/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="instagram-icon-hov"
                    src={Instagram}
                    alt="instagram"
                  />
                  <h3>Instagram</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default ContactPage;
