import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Slika1jpg from '../../../images/otkupljujemo/slike/slika1-min.jpg';
import Slika1webp from '../../../images/otkupljujemo/slike/slika1-min.webp';
import Slika2jpg from '../../../images/otkupljujemo/slike/slika2-min.jpg';
import Slika2webp from '../../../images/otkupljujemo/slike/slika2-min.webp';
import Slika3jpg from '../../../images/otkupljujemo/slike/slika3-min.jpg';
import Slika3webp from '../../../images/otkupljujemo/slike/slika3-min.webp';
import Slika4jpg from '../../../images/otkupljujemo/slike/slika4-min.jpg';
import Slika4webp from '../../../images/otkupljujemo/slike/slika4-min.webp';
import Slika5jpg from '../../../images/otkupljujemo/slike/slika5-min.jpg';
import Slika5webp from '../../../images/otkupljujemo/slike/slika5-min.webp';
import Slika6jpg from '../../../images/otkupljujemo/slike/slika6-min.jpg';
import Slika6webp from '../../../images/otkupljujemo/slike/slika6-min.webp';
import Slika7jpg from '../../../images/otkupljujemo/slike/slika7-min.jpg';
import Slika7webp from '../../../images/otkupljujemo/slike/slika7-min.webp';
import Slika8jpg from '../../../images/otkupljujemo/slike/slika8-min.jpg';
import Slika8webp from '../../../images/otkupljujemo/slike/slika8-min.webp';

const images = [
  { jpgURL: Slika1jpg, webpURL: Slika1webp, alt: 'Ulje na platnu' },
  { jpgURL: Slika2jpg, webpURL: Slika2webp, alt: 'Ikona' },
  { jpgURL: Slika3jpg, webpURL: Slika3webp, alt: 'Akvarel' },
  { jpgURL: Slika4jpg, webpURL: Slika4webp, alt: 'Naiva' },
  { jpgURL: Slika5jpg, webpURL: Slika5webp, alt: 'Predmet od zada' },
  { jpgURL: Slika6jpg, webpURL: Slika6webp, alt: 'Africka umetnost' },
  { jpgURL: Slika7jpg, webpURL: Slika7webp, alt: 'Figura od bronze' },
  { jpgURL: Slika8jpg, webpURL: Slika8webp, alt: 'Predmeti od slonovace' },
];

const SlikePage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup">
        <p>
          <span>{t('slike')}:</span>
          {t('slikeContent')}
        </p>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default SlikePage;
