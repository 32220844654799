import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Porculan1jpg from '../../../images/otkupljujemo/porculan/porculan1-min.jpg';
import Porculan1webp from '../../../images/otkupljujemo/porculan/porculan1-min.webp';
import Porculan2jpg from '../../../images/otkupljujemo/porculan/porculan2-min.jpg';
import Porculan2webp from '../../../images/otkupljujemo/porculan/porculan2-min.webp';
import Porculan3jpg from '../../../images/otkupljujemo/porculan/porculan3-min.jpg';
import Porculan3webp from '../../../images/otkupljujemo/porculan/porculan3-min.webp';
import Porculan4jpg from '../../../images/otkupljujemo/porculan/porculan4-min.jpg';
import Porculan4webp from '../../../images/otkupljujemo/porculan/porculan4-min.webp';
import Porculan5jpg from '../../../images/otkupljujemo/porculan/porculan5-min.jpg';
import Porculan5webp from '../../../images/otkupljujemo/porculan/porculan5-min.webp';
import Porculan6jpg from '../../../images/otkupljujemo/porculan/porculan6-min.jpg';
import Porculan6webp from '../../../images/otkupljujemo/porculan/porculan6-min.webp';
import Porculan7jpg from '../../../images/otkupljujemo/porculan/porculan7-min.jpg';
import Porculan7webp from '../../../images/otkupljujemo/porculan/porculan7-min.webp';
import Porculan8jpg from '../../../images/otkupljujemo/porculan/porculan8-min.jpg';
import Porculan8webp from '../../../images/otkupljujemo/porculan/porculan8-min.webp';

const images = [
  { jpgURL: Porculan1jpg, webpURL: Porculan1webp, alt: 'Figura od porculana' },
  { jpgURL: Porculan2jpg, webpURL: Porculan2webp, alt: 'Stara figura' },
  { jpgURL: Porculan3jpg, webpURL: Porculan3webp, alt: 'Cinija' },
  { jpgURL: Porculan4jpg, webpURL: Porculan4webp, alt: 'Servis za kafu' },
  { jpgURL: Porculan5jpg, webpURL: Porculan5webp, alt: 'Cajnik' },
  { jpgURL: Porculan6jpg, webpURL: Porculan6webp, alt: 'Staklena flasa' },
  { jpgURL: Porculan7jpg, webpURL: Porculan7webp, alt: 'Staklena casa' },
  { jpgURL: Porculan8jpg, webpURL: Porculan8webp, alt: 'Pehar' },
];

const PorculanPage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup">
        <p>
          <span>{t('porculan')}:</span>
          {t('porculanContent')}
        </p>
        <p>
          <span>{t('staklo')}:</span>
          {t('stakloContent')}
        </p>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default PorculanPage;
