import { RenderImages } from 'components';
import { useTranslation } from 'react-i18next';
import Upaljac1jpg from '../../../images/otkupljujemo/upaljaci/upaljaci1-min.jpg';
import Upaljac1webp from '../../../images/otkupljujemo/upaljaci/upaljaci1-min.webp';
import Upaljac2jpg from '../../../images/otkupljujemo/upaljaci/upaljaci2-min.jpg';
import Upaljac2webp from '../../../images/otkupljujemo/upaljaci/upaljaci2-min.webp';
import Upaljac3jpg from '../../../images/otkupljujemo/upaljaci/upaljaci3-min.jpg';
import Upaljac3webp from '../../../images/otkupljujemo/upaljaci/upaljaci3-min.webp';
import Upaljac4jpg from '../../../images/otkupljujemo/upaljaci/upaljaci4-min.jpg';
import Upaljac4webp from '../../../images/otkupljujemo/upaljaci/upaljaci4-min.webp';
import Upaljac5jpg from '../../../images/otkupljujemo/upaljaci/upaljaci5-min.jpg';
import Upaljac5webp from '../../../images/otkupljujemo/upaljaci/upaljaci5-min.webp';
import Upaljac6jpg from '../../../images/otkupljujemo/upaljaci/upaljaci6-min.jpg';
import Upaljac6webp from '../../../images/otkupljujemo/upaljaci/upaljaci6-min.webp';
import Upaljac7jpg from '../../../images/otkupljujemo/upaljaci/upaljaci7-min.jpg';
import Upaljac7webp from '../../../images/otkupljujemo/upaljaci/upaljaci7-min.webp';
import Upaljac8jpg from '../../../images/otkupljujemo/upaljaci/upaljaci8-min.jpg';
import Upaljac8webp from '../../../images/otkupljujemo/upaljaci/upaljaci8-min.webp';

const images = [
  { jpgURL: Upaljac1jpg, webpURL: Upaljac1webp, alt: 'mont blanc penkalo' },
  { jpgURL: Upaljac2jpg, webpURL: Upaljac2webp, alt: 'pelikan penkalo' },
  { jpgURL: Upaljac3jpg, webpURL: Upaljac3webp, alt: 'cartier penkalo' },
  { jpgURL: Upaljac4jpg, webpURL: Upaljac4webp, alt: "caran d'Ache penkalo" },
  { jpgURL: Upaljac5jpg, webpURL: Upaljac5webp, alt: 'waterman penkalo' },
  { jpgURL: Upaljac6jpg, webpURL: Upaljac6webp, alt: 'dupont upaljaci' },
  { jpgURL: Upaljac7jpg, webpURL: Upaljac7webp, alt: 'cartier upaljaci' },
  { jpgURL: Upaljac8jpg, webpURL: Upaljac8webp, alt: 'dunhill upaljaci' },
];

const UpaljaciPage = () => {
  const { t } = useTranslation();

  return (
    <section>
      <article className="oa-otkup">
        <p>
          <span>{t('penkala')}:</span>Mont Blanc, Pelikan, Cartier, Waterman,
          Caran dAche
        </p>
        <p>
          <span>{t('upaljaci')}:</span>Dupont, Cartier, Dunhill
        </p>
      </article>
      <article className="oa-otkup-grid-images">
        <RenderImages images={images} />
      </article>
    </section>
  );
};

export default UpaljaciPage;
